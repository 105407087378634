import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import NProgress from "nprogress"; // 导入 nprogress模块

import "nprogress/nprogress.css"; // 导入样式，否则看不到效果

NProgress.configure({ showSpinner: false }); // 显示右上角螺旋加载提示
Vue.use(VueRouter)


//路由重复点击报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.meta.type == 'detail' || to.name == 'home' || from.name == 'home') {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

router.beforeEach((to, from, next) => {
  NProgress.start(); //开启进度条
  //中间写其他的项目中所需要的一些代码，例如有些网页只有登录了才能进，在这里可以做出判断，判断完了满足要求后就可以放行 next()
  next();

});

router.afterEach(() => {
  NProgress.done(); //完成进度条
});
export default router
